var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "sales-detail"
    }
  }, [_c('b-tabs', {
    model: {
      value: _vm.tabIndex,
      callback: function ($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Информация по товарам",
      "active": ""
    }
  }, [_vm.tabIndex === 0 ? _c('product-info', {
    attrs: {
      "products": _vm.products,
      "is_loading": _vm.isLoading
    },
    on: {
      "filter_products": _vm.filter_products
    }
  }) : _vm._e(), _vm.items_total > 20 && _vm.tabIndex === 0 ? _c('pagination', {
    attrs: {
      "total": _vm.items_total,
      "table_name": "sales-docs"
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  }) : _vm._e()], 1), _c('b-tab', {
    attrs: {
      "title": "Информация по чекам"
    }
  }, [_vm.tabIndex === 1 ? _c('checks-info', {
    attrs: {
      "extra": _vm.extra_params
    }
  }) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }