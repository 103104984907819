<template>
  <div class="checks-info-content">
    <div class="card p-0 mt-3 overflow-hidden card-table">
      <resizable-table
        ref="table_cashbox_sales"
        v-table-resize
        table_name="table_cashbox_sales"
        :default_fields="fields"
        :items="checks"
        :busy="isLoading"
        class="table-sales-checks overflow-hidden"
      >
        <template #head_id>
          <b-form-checkbox v-model="select_all" />
        </template>

        <template #body_id="data">
          <div class="th-wrapper d-flex justify-content-center">
            <b-form-checkbox
              v-model="selected"
              class="cursor"
              :value="data.value"
            />
          </div>
        </template>

        <template #body_date="data">
          {{ convertDatetoTime(data.value) }}
        </template>

        <template #body_cash="data">
          <div class="card-table__left-text">
            {{ data.item.cash?.name }}
          </div>
        </template>

        <template #body_depth="data">
          <div class="card-table__right-text">
            {{ data.value }}
          </div>
        </template>

        <template #body_discount="data">
          <div class="card-table__right-text">{{ Number(data.value).toFixed(2) || 0 }} ₽</div>
        </template>

        <template #body_sum="data">
          <div class="card-table__right-text">{{ Number(data.value).toFixed(2) || 0 }} ₽</div>
        </template>

        <template #body_sum_discount="data">
          <div class="card-table__right-text">{{ Number(data.item.sum - data.item.discount).toFixed(2) }} ₽</div>
        </template>
      </resizable-table>
    </div>
    <pagination
      :total="items_total"
      table_name="check-info"
      @change_pagination="handlerPagination"
    />
  </div>
</template>

<script>
  import formatDate from '@/utils/formatDate'
  import * as R from 'ramda'
  import { mapGetters } from 'vuex'
  import Pagination from '@/views/shared/components/table/Pagination'

  export default {
    components: {
      Pagination
    },

    apollo: {
      Checks: {
        query: require('../../gql/getChecks.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch.id,
            document_head: this.$route.params.id,
            pagination: this.pagination
            // period: {
            //   begin: this.data_range[0],
            //   end: this.data_range[1]
            // }
          }
        },
        result({ data }) {
          this.checks = R.pathOr([], ['Checks', 'documents'], data)
          this.items_total = data?.Checks?.total || 0
          this.isLoading = false
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        }
      }
    },

    data: function () {
      return {
        isLoading: true,
        checks: [],
        select_all: [],
        selected: [],
        data_range: '',
        items_total: 0,
        pagination: {
          skip: 0,
          take: 20
        }
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),

      fields: function () {
        return [
          {
            key: 'id',
            label: '',
            class: 'center p-0 td-id',
            width: 40,
            checked: true
          },
          {
            key: 'cash',
            label: 'Номер кассы',
            width: 150,
            checked: true
          },
          {
            key: 'z_number',
            label: 'Номер Z отчета',
            class: 'center',
            width: 100,
            sortable: true,
            checked: true
          },
          {
            key: 'number',
            label: 'Чек',
            class: 'center',
            width: 100,
            sortable: true,
            checked: true
          },
          {
            key: 'date',
            label: 'Время',
            class: 'center',
            width: 150,
            sortable: true,
            checked: true
          },
          {
            key: 'depth',
            label: 'Количество позиций',
            class: 'center',
            width: 110,
            sortable: true,
            checked: true
          },
          {
            key: 'sum',
            label: 'Сумма без скидки, ₽',
            class: 'center',
            width: 150,
            sortable: true,
            checked: true
          },
          {
            key: 'discount',
            label: 'Скидка, ₽',
            class: 'center',
            width: 100,
            checked: true
          },
          {
            key: 'sum_discount',
            label: 'Сумма со скидкой',
            class: 'center',
            width: 100,
            checked: true
          },
          {
            key: 'shift_number',
            label: 'Номер смены',
            class: 'center',
            width: 100,
            sortable: true,
            checked: true
          }
        ]
      }
    },

    watch: {
      select_all(newVal) {
        if (!newVal) return (this.selected = [])

        this.checks.forEach((item) => {
          const id = item.id
          if (!this.selected.includes(id)) this.selected.push(id)
        })
      }
    },

    methods: {
      convertDatetoTime(date) {
        if (date) return formatDate(new Date(date), 'datetime', true)
      },

      updateDateRange() {
        this.$apollo.queries.Checks.refetch()
      },
      handlerPagination({ current_page = null, take = null }) {
        this.isLoading = true
        if (current_page) this.pagination.skip = Number((current_page - 1) * take)
        if (take) this.pagination.take = take
        this.$apollo.queries.Checks.refetch()
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .table-sales-checks {
      margin-bottom: -1px !important;

      .td-id {
        .th-wrapper {
          justify-content: center;
        }
      }
    }
  }

  .table-sales-checks {
    margin-bottom: -1px !important;

    .td-id {
      .th-wrapper {
        justify-content: center;
      }
    }
  }
  .checks-info-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    .card-table {
      border: none;
      display: flex;
      flex-direction: column;
      flex: 1;

      &__right-text {
        text-align: right;
      }
      &__left-text {
        text-align: left;
      }
    }
  }
</style>
