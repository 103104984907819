var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "checks-info-content"
  }, [_c('div', {
    staticClass: "card p-0 mt-3 overflow-hidden card-table"
  }, [_c('resizable-table', {
    directives: [{
      name: "table-resize",
      rawName: "v-table-resize"
    }],
    ref: "table_cashbox_sales",
    staticClass: "table-sales-checks overflow-hidden",
    attrs: {
      "table_name": "table_cashbox_sales",
      "default_fields": _vm.fields,
      "items": _vm.checks,
      "busy": _vm.isLoading
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.select_all,
            callback: function ($$v) {
              _vm.select_all = $$v;
            },
            expression: "select_all"
          }
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (data) {
        return [_c('div', {
          staticClass: "th-wrapper d-flex justify-content-center"
        }, [_c('b-form-checkbox', {
          staticClass: "cursor",
          attrs: {
            "value": data.value
          },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v;
            },
            expression: "selected"
          }
        })], 1)];
      }
    }, {
      key: "body_date",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(_vm.convertDatetoTime(data.value)) + " ")];
      }
    }, {
      key: "body_cash",
      fn: function (data) {
        var _data$item$cash;

        return [_c('div', {
          staticClass: "card-table__left-text"
        }, [_vm._v(" " + _vm._s((_data$item$cash = data.item.cash) === null || _data$item$cash === void 0 ? void 0 : _data$item$cash.name) + " ")])];
      }
    }, {
      key: "body_depth",
      fn: function (data) {
        return [_c('div', {
          staticClass: "card-table__right-text"
        }, [_vm._v(" " + _vm._s(data.value) + " ")])];
      }
    }, {
      key: "body_discount",
      fn: function (data) {
        return [_c('div', {
          staticClass: "card-table__right-text"
        }, [_vm._v(_vm._s(Number(data.value).toFixed(2) || 0) + " ₽")])];
      }
    }, {
      key: "body_sum",
      fn: function (data) {
        return [_c('div', {
          staticClass: "card-table__right-text"
        }, [_vm._v(_vm._s(Number(data.value).toFixed(2) || 0) + " ₽")])];
      }
    }, {
      key: "body_sum_discount",
      fn: function (data) {
        return [_c('div', {
          staticClass: "card-table__right-text"
        }, [_vm._v(_vm._s(Number(data.item.sum - data.item.discount).toFixed(2)) + " ₽")])];
      }
    }])
  })], 1), _c('pagination', {
    attrs: {
      "total": _vm.items_total,
      "table_name": "check-info"
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }