<template>
  <section id="sales-detail">
    <!-- <breadcrumbs :title="title" :extra="extra_params" /> -->
    <b-tabs v-model="tabIndex">
      <b-tab
        title="Информация по товарам"
        active
      >
        <product-info
          v-if="tabIndex === 0"
          :products="products"
          :is_loading="isLoading"
          @filter_products="filter_products"
        />
        <pagination
          v-if="items_total > 20 && tabIndex === 0"
          :total="items_total"
          table_name="sales-docs"
          @change_pagination="handlerPagination"
        />
      </b-tab>
      <b-tab title="Информация по чекам">
        <checks-info
          v-if="tabIndex === 1"
          :extra="extra_params"
        />
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>
  import ProductInfo from '../../components/cashbox/ProductInfo'
  import ChecksInfo from '../../components/cashbox/ChecksInfo'
  import * as R from 'ramda'
  import Pagination from '@/views/shared/components/table/Pagination'
  import { mapActions } from 'vuex'

  export default {
    components: {
      ProductInfo,
      ChecksInfo,
      Pagination
    },

    apollo: {
      OutgoingHead: {
        query: require('../../gql/getOutgoingHead.gql'),
        fetchPolicy: 'no-cache',
        debounce: 300,
        variables() {
          return {
            id: this.$route.params.id,
            pagination: this.pagination,
            search: this.pagination.search
          }
        },
        result({ data }) {
          const result = R.pathOr([], ['OutgoingHead'], data)
          this.products = R.pathOr([], ['OutgoingHead', 'paginationSpecifications', 'specification'], data)
          this.isLoading = false
          this.items_total = data?.OutgoingHead.paginationSpecifications?.total || 0
          this.extra_params.date = result.date
          this.extra_params.fixed = result.fixed
          this.extra_params.id = result.id
          this.extra_params.number = result.number
          this.setBreadcrumbs({ ...this.extra_params, is_go_back: true })
          return data
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        }
      },

      TaxRates: {
        query: require('../../gql/getTaxRates.gql'),
        result({ data }) {
          this.taxRateList = R.pathOr([], ['TaxRates'], data)
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    data: function () {
      return {
        tabIndex: 0,
        title: this.$route.meta.title,
        isLoading: true,
        products: [],
        extra_params: {
          date: '',
          fixed: '',
          id: '',
          number: ''
        },
        items_total: 0,
        pagination: {
          skip: 0,
          take: 20,
          search: ''
        }
      }
    },

    created() {
      if (this.$route.params.extra) {
        this.extra_params = this.$route.params.extra
        this.products = this.$route.params.products
      }
    },
    beforeMount() {
      this.setBreadcrumbs({ is_go_back: true })
    },
    beforeDestroy() {
      this.setBreadcrumbs({})
    },
    methods: {
      ...mapActions({
        setBreadcrumbs: 'breadcrumbs/set_current'
      }),
      handlerPagination({ current_page = null, take = null }) {
        this.isLoading = true
        if (current_page) this.pagination.skip = Number((current_page - 1) * take)
        if (take) this.pagination.take = take
        this.$apollo.queries.OutgoingHead.refetch()
      },
      async filter_products(val) {
        this.pagination.search = val
        this.$apollo.queries.OutgoingHead.refetch()
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    #sales-detail {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .tabs .nav-tabs li {
      width: auto;

      a {
        color: #888888;
        font-weight: 500;
        font-size: 18px;

        &.active {
          color: #313131;
        }
      }
    }

    .tabs {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    .tab-content {
      display: flex;
      flex-direction: column;
      flex: 1;
      .tab-pane {
        display: flex;
        flex: 1;
        flex-direction: column;
      }
    }
  }

  #sales-detail {
    display: flex;
    flex-direction: column;
    flex: 1;
    .tabs .nav-tabs li {
      a {
        &.active {
          color: #313131;
        }
      }
    }
  }
</style>
